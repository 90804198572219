$bg-white: #FFF;
$bg-black: #2E2E2E;

$gray-100: #f8f9fa;
$gray-900: #212529;

$gray-1: #E1E1E1;
$gray-2: #C7C7C7;
$gray-3: #ADADAD;
$gray-4: #7A7A7A;
$gray-5: #474747;

$blue-1: #66ACFF;
$blue-2: #318CF7;
$blue-3: #0065DD;
$blue-4: #005AC4;
$blue-5: #004EAB;

$orange-1: #FF8766;
$orange-2: #FF734D;
$orange-3: #FD4A19;
$orange-4: #E34216;
$orange-5: #C93B14;

$white: #FFFFFF;
$black: #2E2E2E;

$cinza: #CFDCE6;
$amarelo: #FFB700;
$azul: #8ACCDB;

$blue: #0d6efd;
$indigo: #6610f2;
$purple: #6f42c1;
$pink: #d63384;
$red: #dc3545;
$orange: #fd7e14;
$yellow: #ffc107;
$green2: #198754;
$teal: #20c997;
$cyan: #0dcaf0;

$gray-s: #E6EDF2;
$accent-s: #FFEBE6;
$primary-s: #E6F1FF;
$success-s: #DAF2DD;
$warning-s: #FFF7E6;
$danger-s: #FFE9E6;
$info-s: #D9F2FF;


// scss theme-color-variables
$primary: $blue-3;
$secondary: $orange-3;
$success: #3BC14A;
$info: #4bb7f1;
$warning: #FFB30F;
$danger: #FF715B;
$light: $gray-100;
$dark: $gray-900;