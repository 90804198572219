@import url('https://fonts.googleapis.com/css2?family=Unbounded:wght@500&display=swap');
.total-nav{
    position: fixed;
    z-index: 1000;
    @media(max-width:1280px){
display: none;;
    }
}
.Nav-fixed{
  background-color: transparent;
  .logo-navbar{
    background-image: url("../../../../public/assets/logo-light.png");
  }

  a{
    color:#fff;
  }
  a:hover{
 //  color: #a3a4a5;
}
.text-menu-a{
  cursor: pointer;
  color:#fff;
}
.text-menu-a:hover{
//  color: #a3a4a5;
}
.hamburguer{
  background: #fffefe !important;
  &::after{
      background: #fffefe !important;
    }
  &::before{
      background: #fffefe !important;
    }
}
}
.Nav-scroll{
  background-color: #fff;
  box-shadow: 0 7px 7px 1px #054f77;
  .menu-user{
    span{
      color: #064ea0;
    }
  }
  .logo-navbar{
  background-image: url("../../../../public/assets/logo-dark.png");
  }
  a{
    color:#4A4A4A;
  }
  a:hover{
    color: rgb(93, 127, 162);
}
.text-menu-a{
  cursor: pointer;
  color:#4A4A4A;
}
.text-menu-a:hover{
  color: rgb(93, 127, 162);
}
}
.navMenu-atv{
  box-shadow: 0 !important;
}
.Nav{
    z-index: 1000;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 90px;
    display: flex;
    // font-family: 'Unbounded', cursive;
    font-size: 18px;
    // justify-content:space-around !important;
    padding-top: 10px;
    padding-bottom: 20px;
    padding-left: 90px;
    padding-right: 90px;
    background-repeat: no-repeat, repeat;
    background-size: 100%;
   
    @media(max-width:800px){
    background-repeat: no-repeat, repeat;
    height: 90px;
    background-size: 100%;
    display: block;
    padding-top: 15px;
    padding-left: 20px;
    padding-right: 20px;
      }
     
      .menu{
      display: flex;
      align-items: center;
    //  padding-bottom: 12px;
       width: 120%;
       margin-left: 5%;
       justify-content: space-around !important;
        @media(max-width:800px){
            display: none;
        }
        a:hover{
          color: gold;
        }
      }
      .div-language{
        width: 17px;
        height: 17px;
        border-radius: 50%;
        background-color: #007bff;
        background-repeat: no-repeat, repeat;
        background-size: 100%;
        margin-top: 2px;
      }
      .lg-us{
        background-image: url("../../../../public/assets/us.png");
      }
      .lg-es{
        background-image: url("../../../../public/assets/es.png");
      }
      .lg-br{
        background-image: url("../../../../public/assets/br.png");
      }
    .logo-navbar{
         width: 120px;
        height:60px;
        background-repeat: no-repeat, repeat;
        background-size: 100%;
        @media(max-width:1280px){
          display: none;
      }
    }
    ul{
      //  width: 130%;
        list-style-type: none;
        display: flex;
        max-height: 300px;
        justify-content: space-around !important;
        
        li{
          font-family: 'Unbounded', cursive;
    font-size: 18px;
            text-decoration: none;
           margin-right: 30px;
           height: 20px;
           margin-top: 10px;

            a{
                display: flex!important;
                font-size: 22px;
                text-decoration: none;
                transition: all ease .3s;
                text-rendering: optimizeLegibility!important;
                cursor: pointer;
                height: 20px;
            }
           
            
        }
        
    }
    .button-geral{
        color: #fff;
       border: none;
        font-family: Sintony,sans-serif;
        border-radius: 16px 4px;
        min-width: 170px;
        max-width: 100px;
        line-height: 18px;
        text-align: center;
        background: #064ea0;
        position: relative;
        height: 50px;
        margin-left: 10%;
        cursor: pointer;
    }
    .button-geral:hover{
        background-color: rgb(38, 78, 153);
        
    }

  
    }
  
    .logo-a{
      background-color: #007bff !important;
      width: 80% !important;
      margin:0px;
      padding: 0px;
    }

    //menu toggle=======================================
  .iconMenu{
    display: none;
  }
 .iconMenu {
  @media(max-width:800px){
    display: block;
   z-index: 4;
   width: fit-content;
   height: 20px;
   cursor: pointer;
   &.iconActive{
    
      .hamburguer{
        background: transparent;
        &::after{
            top:0;
            background: #6d6c6c;
            transform: rotate(225deg);
          }
        &::before{
            top:0;
            background: #6d6c6c;
            transform: rotate(135deg);
          }
      }
    }
   .hamburguer{
    top: 40%;
    right: 5%;
    width: 25px;
    height: 3px;
    background: #6d6c6c;
    position: absolute;
    transition: 0.5s;
    &::before{
      top: -9px;
      content:'';
      position: absolute;
      width: 25px;
      height: 3px;
      background: #6d6c6c;
      transition: 0.5s;
    }
    &::after{
      top: 9px;
      content:'';
      position: absolute;
      width: 25px;
      height: 3px;
      background: #6d6c6c;
      transition: 0.5s;
    }
  }
}
 }

 

.btn-group{
  @media(max-width:800px){
    .bnt-nome-login{
      margin-left: 22%;
      margin-bottom: 10px;
    }
    display: inline !important;
    align-items: center !important;
  }
  
}
.ms-auto{
  @media(max-width:800px){
  
    margin-left: 1px !important;
  
  }
}
.menu-home{
  @media(max-width:800px){
    width: 10% !important;
  }
}
.menu-ul-mobile{
  display: block !important;
  text-align: left !important;
}
.menu-mobile{
 // background-image: url("../../../public/assets/fundo-menu.png");
  background-repeat: no-repeat;
  background-size: 100%;
  border-top: 1px solid gray;
  position:relative;
  top:-20 !important;
    width: 100%;
    height: 200px;
   -webkit-transition: 0.5s ease-in-out;
   -moz-transition: 0.5s ease-in-out;
   -o-transition: 0.5s ease-in-out;
   transition: 0.5s ease-in-out;
    background-color: #fff;
   box-shadow: 4px 8px 7px 1px  #054f77;
   padding-left: 30px;
   padding-top: 20px;
   padding-bottom: 20px;
   display:list-item;
   flex-direction: column !important;
    .divisao{
        width: 90%;
        height: 1px;
        margin-top: 20px;
        background-color: #969595;
    }
    li{
        text-decoration: none;
        // font-family: 'Unbounded', cursive;
        font-size: 18px !important;
        text-align: left !important;
       margin-right: 20px;
       height: 20px;
       margin-top: 18px;
       align-items:initial;
      // line-height: 25px !important;
        a{
            color: #054f77;
            display: flex!important;
            text-decoration: none;
            transition: all ease .3s;
            text-rendering: optimizeLegibility!important;
            text-align: justify !important;
            cursor: pointer;
            height: 40px !important;
            margin-bottom: 20px !important;
          //  line-height: 25px !important;
        }
        a:hover{
            color: gold;
        }
        
    }
    @media(min-width:1100px){
      display: none;
    }

}

.not-menu{
    height: 0px;
    .menu-ul-mobile{
      display: none !important;
    }
}
.logo-lik{
width: 10% !important;

  @media(max-width:800px){
    width: 100px !important;
    height: 40px;
    margin-left: 0;
  }

}
.div-erro-login{
  width: 90%;
  height: 60px;
  margin-left: 20px;
  margin-bottom: 10px;
  font-family: Sintony,sans-serif;
  text-align: center;
}
.text-logado{
  font-family: Sintony,sans-serif;
  font-weight: bold;
  margin-top: 20px;
  color: #064ea0;
  cursor: pointer;
  margin-left: 40PX;
}
.menu-mobile::marker {
  color: transparent;
}
.menu-ul-mobile{
  @media(min-width:1100px){
    display: none !important;
  }
}
.sub-menu{
  opacity: 0;
  color: #4A4A4A;
  visibility: hidden;
  margin-top: 3px !important;
  background-color: #F9F9FB;
  padding-top: 50px !important;
  padding-bottom: 70px !important;
  padding: 40px;
  width: 300px !important;
  min-height: 400px !important;
  border-radius: 10px;
  position: absolute;
  flex-direction: column !important; 
  box-shadow: 4px 9px 8px 2px  #054f77;
  li{
    height: 50px;
    cursor: pointer;
    margin-bottom: 8px;
  }
  li:hover{
    color: #807e7e;
    border-bottom:1px solid rebeccapurple;
  }
}
.sub-menu:hover{
  visibility: visible;
  opacity: 1;
}
.solution:hover .sub-menu{
  visibility: visible;
  opacity: 1;
}
.transparence:hover .sub-menu-transp{
  visibility: visible;
  opacity: 1;
}
.sub-menu-transp{
  opacity: 0;
  visibility: hidden;
  margin-top: 3px !important;
  color: #4A4A4A;
  background-color: #F9F9FB;
  padding-top: 40px !important;
  padding-bottom: 40px !important;
  padding: 40px;
  width: 300px !important;
  min-height: 200px !important;
  border-radius: 10px;
  position: absolute;
  flex-direction: column !important; 
  box-shadow: 4px 9px 8px 2px  #054f77;
  li{
    height: 50px;
    cursor: pointer;
    margin-bottom: 8px;
  }
  li:hover{
    color: #807e7e;
    border-bottom:1px solid rebeccapurple;
  }
}
.img-navmess{
  width: 50px;
  height: 50px;
}
.img-nav{
  width: 50px;
  height: 50px;
  margin-right: 18px;
}
.menu-fixo{
  align-items: center;
  display: flex;
  a{
    color: gold !important;
    text-decoration: none !important;

  }
}
.menuic{
  margin-right: 60px;
}
.menu-user{
  align-items: center;
  display: flex;
  font-size: 25px;
  color: gold;
  cursor: pointer;
  h2{
    line-height: 16px;
  }
  h2:hover{
    color: #F9F9FB;
  
  }
  span{
    text-align: center;
    color: #fff;
    font-size: 15px;
  }
}

#bloco-recupera2{
  @media(max-width:1280px){ 
    color: #fff !important;
  }
}

#bloco-segundo-texto{
  @media(max-width:1280px){ 
    color: #fff !important;
  }
}
#esqueceusenha{
  @media(max-width:1280px){ 
    color: gold !important;
    font-size: 20px !important;
    margin-top: 20px !important;
    margin-bottom: 20px !important;
  }
}