@import '../../../styles/colors.scss';

#navbar-header-dashboard{
  margin-left: 40px;
 
  .conteudo-nav{
    padding: 7px !important;
    margin-bottom: 5px !important;
width: 93% !important;

    @media(max-width:1280px){
    position: fixed;
    top: 0;
    left: 0;
    z-index: 800;
    background-color: #FFF;
    width: 100% !important;
    min-height: 60px;
    }
  }
  
  @media(max-width:1280px){
    width: 100% !important;
    margin-left: 0px;
  }
  >div{
    border-bottom: $gray-1 1px solid;
  }
  
  position: relative;
  padding: 0 50px;
  @media(max-width:1280px){
    padding: 0 0px;

  }
  #sidebarToggleTop{
    display: none;
    @media(max-width:1280px){
      display: block;
      color: #0065DD;
      font-size: 22px;
    }
  }
  img{
    width: 32px ;
    height: 32px;
    border: 1px solid #4BB7F1;
    border-radius: 50%;
    margin-right: 5px;
  }
  .name-perfil{
    margin: 0;
  }
  p {
    margin: 0;
    margin-bottom: 0px;
    &.saudacao{
      padding-right: 10px; 
      margin-bottom: 0; 
      color: $gray-3;
    }
  }
  .row-gray{
    margin-top: 0.5rem !important;
  }
  
  .content-user{
    @media(max-width:1280px){
      display: none !important;
    }
    
  }
  
  
}
.container-fluid{
  @media(max-width:1280px){
  // padding: 13px !important;
 // padding-left: 25px !important;
   padding-right: 25px !important;
  }

}
//margin top===============================
.buttonUpdate{
  margin-top: 20px;
  border: 1px solid #ADADAD;
  background-color: white;
  padding: 8px 16px;
  border-radius: 50px;
  color: #ADADAD;
}

 #navbarMenuD{
   background-color: rgb(46, 45, 45);
   z-index: 1000;
   align-items: center;
 }

 .login{
  @media(max-width:820px){

   padding-left: 0 !important;
  }
 }
 .menuFotos{
  background-color: rgb(70, 69, 69);
  transition: 1s;
 }
 .botaoFotos{
  span{
    text-decoration: none;
    color: #C7C7C7;
    font-size: .9rem;
    margin-right: 10px;
    cursor:pointer;
  }
  
 }
 .botaoFotos:hover{
  .text{
color: #FFF !important;
}

}
.menuSumir{
  display:none;
  transition: 1s;
}
.logo-navbar2{
  background-image: url("../../../../public/assets/logo-dark.png");
  background-repeat: no-repeat, repeat;
  background-size: 40%;
  height: 120%;
  @media(max-width:800px){
    background-size: 100%;
    margin-top: 5px;
  }
  }
  .logo-lik2{
    align-items: center;
    padding-top: 10px;
    width: 10% !important;
   
      @media(max-width:800px){
        width: 60px !important;
        height: 40px;
        margin-left: 0;
        display: none;
      }
    
    }
    .logo-b{
     // background-color: #007bff !important;
      width: 80% !important;
      margin:0px;
      padding: 0px;
      color: #0065DD;
   
      text-decoration: none;
      align-items: center;
      h1{
        font-size: 17px !important;
      }
    }
    .dropdow{
      position: absolute;
      top:48px !important;
      right: 60px;
      width: 200px;
      height: 80px;
      text-align: center;
      padding-top: 10px;
      line-height: 30px;
      background-color: #FFF;
      box-shadow: 0 5px 6px 1px #9d9d9f;
      z-index: 800;
      li{
        cursor: pointer;
      }
      li:hover{
        color: #007bff;
      }
      @media(max-width:800px){
        right: 10px;
      }
    }
    .no-dropdow{
      position: absolute;
      top:30px;
      right: 60px;
      width: 200px;
      height: 0px !important;
      text-align: center;
      // padding-top: 10px;
      line-height: 30px;
      background-color: #FFF;
      @media(max-width:800px){
        right: 20px;
      }
     // box-shadow: 0 5px 6px 1px #9d9d9f;
      ul{
        display: none;
      }
    }