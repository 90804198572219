@import url('https://fonts.cdnfonts.com/css/avenir');

@import url('https://fonts.googleapis.com/css2?family=Unbounded:wght@500&display=swap');

.content-home {
//    font-family: 'Unbounded', cursive;
    width: 100%;
    padding-top: 100px;
    min-height: 700px;
    background-color: #054f77;
    height: auto !important;
    flex-direction: column; 
    align-items: center;
    display: flex;
    @media(max-width:1280px) {
        padding: 0;
        min-height: 100vh;
    }
    .content{
        position: relative;
        width: 100%;
        min-height: 700px;
        padding-left: 80px;
        padding-right: 80px;
        justify-content:flex-end;
        background-image: url("../../assets/fundo-portal.png");
        background-size: 100%;
        background-repeat: no-repeat, repeat;
        align-items: center;
        display: flex;
       
        
        @media(max-width:1280px) {
       // padding-left: 10px;
       // padding-right: 10px;
        justify-content:center;
        background-image: none;
      //  min-height: 90vh;
        }
        @media(max-width:1280px) {
            padding: 0px;
           
            justify-content:center;
          
            min-height: 100vh;
            }
            @media(max-width:1280px) {
              //  padding-left: 10px;
             //   padding-right: 10px;
                justify-content:center;
               // min-height: 750px;
                }
    }
    h2{
        font-size: 30px;
        color: aliceblue;
    }
   
   

}
.menu-mob{
display: flex;
a{
    h2{
        color: gold !important;
    }
    text-decoration: none;
}
    @media(min-width:900px) {
        display: none;
    }
}
.submenu{
    justify-content: center;
margin-top: 30px;
    h2{
        color: gold !important;
        font-size: 20px;
    }
    span{
        font-size: 15px;
    }
}
.container-login{
    justify-content: center !important;
    align-items: center;
    width: 400px;
    height: 480px;
    background-color: #fff;
    margin-bottom: 170px;
   // margin-right: 40px;
    padding: 25px;
    box-shadow: 0 4px 8px 1px #121213;
    @media(max-width:850px) {
        width: 60%;
    }
    @media(max-width:1280px) {
        width: 90%;
        max-width: 500px;
    }
}
.bloco-login{
    text-align: center;
    align-items: center;
    padding-top: 30px;
    padding-bottom: 20px;
    width: 350px;
    height: auto !important;
    margin-bottom: 190px;
    margin-right: 100px;
    background-color: #f0f3fb;
    box-shadow: 0 4px 8px 1px #121213;
    border-radius: 5px;
    .text-btn{
        justify-content: center !important;
        text-align: center !important;
    }  
    @media(max-width:850px) {
        width: 60%;
    }
    @media(max-width:1280px) {
        width: 100vw !important;
        margin-right: 0px;
        // max-width: 500px;
      //  height: 90vh !important;
      background-color:#054f77;
    }
}


  .labelform{
   // position: absolute;
      cursor: text;
      font-size: 80%;
     // font-family: 'Nunito', sans-serif;
      opacity: 1;
      top: -0.4em;
      left: 0.75rem;
      z-index: 100;
      line-height: 1;
      padding: 0 5px 5px;
     
  }

  .btn-entrar{
    margin-top: 30px;
    width: 260px;
    padding: 15px;
    border-radius: 50px;
    background: linear-gradient(0deg, #ffd700 0%, #ffff00 100%);
    @media(max-width:1280px) {
        width: 80%;
        color: #121213;
        border-radius: 5px;
        font-weight: bold;
        font-size: 18px !important;
        padding: 12px;
        margin-top: 30px !important;
        background-color: #ffa500 !important;
        background: #ffa500 !important;
    }
}
.btn-entrar:hover{
    background:  linear-gradient(0deg, #ffa500 0%, #ffff00 100%);
    color: aliceblue;
    @media(max-width:1280px) {
        width: 80%;
        color: #121213;
        border-radius: 5px;
        font-weight: bold;
        font-size: 18px !important;
        padding: 15px;
        background-color: #ffa500 !important;
        background: #ffa500 !important;
    }
}
.register-link{
    margin: auto;
    text-align: center;
    width: 80%;
    margin-top: 20px;
    font-size: 13px;
    line-height: 20px;
    a{
        @media(max-width:1280px) {
            color: #f0f3fb !important;
            font-size: 18px !important;
            text-decoration: none !important;
        }
    }
    @media(max-width:1280px) {
        color: #f0f3fb !important;
        font-size: 20px !important;
        text-decoration: none !important;
    }
}
.labelform{
    text-transform: lowercase;
   
}
.usuario-logado{
    cursor: pointer;
    
        @media(max-width:1280px) {
            margin-top: 5px;
            font-size: 13px !important;
        }
    
}
.bloco-title{
    @media(max-width:1280px) {
     margin-top: 20px;
     margin-bottom: 40px;
    }
}

.content-home2 {
    //    font-family: 'Unbounded', cursive;
        width: 100%;
        padding-top: 100px;
        min-height: 100vh;
        background-color: #054f77;
        height: auto !important;
        flex-direction: column; 
        align-items: center;
        display: flex;
        @media(max-width:1280px) {
            padding: 0;
            min-height: 100vh;
        }
        .content{
            position: relative;
            width: 100%;
            min-height: 100vh;
            padding-left: 80px;
            padding-right: 80px;
            justify-content:flex-end;
            background-image: url("../../assets/fundo-portal.png");
            background-size: 100%;
            background-repeat: no-repeat, repeat;
            align-items: center;
            display: flex;
           
            
            @media(max-width:1280px) {
           // padding-left: 10px;
           // padding-right: 10px;
            justify-content:center;
            background-image:none;
          //  min-height: 90vh;
            }
            @media(max-width:1280px) {
                padding: 0px;
               
                justify-content:center;
              
                min-height: 100vh;
                }
                @media(max-width:1280px) {
                  //  padding-left: 10px;
                 //   padding-right: 10px;
                    justify-content:center;
                   // min-height: 750px;
                    }
        }
        h2{
            font-size: 30px;
            color: aliceblue;
        }
       
       
    
    }
    .supertextr{
        margin-top: 12ox !important;
    }