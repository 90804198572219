
.conteudo-perfil{
        width: 1000px;
    height: 425px;
    box-shadow: 0 4px 8px 1px #121213;
    padding: 15px;
   background-color: #fff;
   top: 20px !important;
   @media (max-width:1280px){
    flex-direction: column;
       width: 100%;
       height: 920px;
       padding: 8px;
     }
 
}
.conteudo-perfil-redefinir{
    width: 1000px;
height: 570px;
box-shadow: 0 4px 8px 1px #121213;
padding: 15px;
background-color: #fff;
top: 20px !important;
@media (max-width:1280px){
flex-direction: column;
   width: 100%;
   height: 1060px;
   padding: 8px;
 }

}

.conteudo-per{
    margin-top: 15px;
    position: relative;
   display: flex;
        width: 1000px;
   // height: 480px;
   
   @media (max-width:1280px){
    flex-direction: column;
       width: 100%;
       height: 1200px;
       padding: 8px;
     }
 
}

.form-cadastro-perfil{
   width: 700px;
   margin-left:30px ;
   // min-height: 560px;
   // box-shadow: 0 4px 8px 1px #121213;
   // padding: 15px;
    background-color: #fff;
    top: 20px !important;
    @media (max-width:1280px){
		width: 100%;
        padding: 8px;
        margin-left:0px ;
	  }
}
.acesso-personalizado-perfil{
    display: flex;
    width: 100%;
    margin-top: 5px;
    background-color: #f9f5f5;
    box-shadow: 0 4px 8px 1px #c6c6ca;
    flex: 0 0 auto;
    padding: 15px;
    @media (max-width:1280px){
		flex-direction: column;
	  }
}
// .acesso-personalizado{
//     width: 100%;
//     margin-top: 5px;
//     height: 208px;
//     background-color: #f9f5f5;
//     box-shadow: 0 4px 8px 1px #c6c6ca;
//     flex: 0 0 auto;
//     padding: 15px;
// }
.check-grupo{
    display: flex;
    text-align: center;
    margin-right: 15px;
    height: 25px;
    margin-bottom: 20px;
    }
//     #grupo{
//         width: 20px;
//         height: 20px;
//         margin-right: 15px;
//         margin-top: 5px;
        
   
// }
.text{
    font-size: 15px;
    color: #6b6868 !important;
}
.btn-editar{
//margin-top: 85px !important;
}
.btn-cancelar{
    margin-top: 25px !important;
    width:100%;
    padding: 15px;
   // border-radius: 50px;
    background: linear-gradient(0deg, #711e1e 0%, #e56e6e 100%);
    color: aliceblue;
    @media(max-width:290px) {
        width: 170px;
    }
}
.btn-cancelar:hover{
    background:  linear-gradient(0deg, #8e1c1c 0%, #b54444 100%);
    color: aliceblue;
}
.btn-cadastrar{
    margin-top: 25px;
    width:100%;
    padding: 15px;
   // border-radius: 50px;
    background: linear-gradient(0deg, #054f77 0%, #4ea1cf 100%);
    color: aliceblue;
    @media(max-width:290px) {
        width: 170px;
    }
}
.btn-cadastrar:hover{
    background:  linear-gradient(0deg, #033049 0%, #2f7aa2 100%);
    color: aliceblue;
}
#btn-mob{
    display: none;
    @media(max-width:1280px) {
     display: block;
    }
}
#btn-desck{
    display: block;
    @media(max-width:1280px) {
     display: none;
    }
}
.logo-perfil{
    margin-top: 0px !important;
    width: 120px;
   height:600px;
   margin-right: 20px;
   bottom: 20px !important;
    background-image: url("../../assets/perfil.png");
    background-size: 80%;
    background-repeat: no-repeat, repeat;
    margin-left: 0px;
    @media(max-width:1280px) {
        display: none;
     }
}
.modal-cadastro-perfil{
    // font-family: 'Unbounded', cursive;
    .modal-content{
    width: 830px;
    height: auto;
    top: -30px;
    left: -30%;
    @media(max-width:1280px) {
        width: 100%;
        left: 0%;
        top:0px;
      }
    }
    .modal-body{
        padding-top: 0 !important;
    }
    h1{
    font-size: 25px;
    }
   
}
// .modal-edit-user{
//     font-family: 'Unbounded', cursive;
//     .modal-content{
//         justify-content: center !important;
//     width: 1200px;
//     height: auto;
//    // top: -30px;
//     left: -70%;
//     @media(max-width:1280px) {
//         width: 100%;
//         left: -3%;
//         top:0px;
//       }
//     }
//     .modal-body{
//         justify-content: center !important;
//         align-items: center !important;
//         display: flex;
//         padding-top: 0 !important;
//         @media(max-width:1280px) {
//           flex-direction: column !important;
//           }
//     }
//     .conteudo-usercad{

//         line-height: 10px !important;
//         padding-top: 0px !important;
//         margin: auto !important;
//         margin-bottom: 250px !important;
//         align-items: center;
//         width: 250px;
//         img{
//             margin-left: 50px;
//             cursor: pointer;
//         }
//         h2{
//             text-align: center;
//             max-height: 25px;
//         }
//         @media(max-width:1280px) {
//             justify-content: center !important;
//             padding: 10px;
//             margin-bottom: 0px !important;
//            padding-left: 40px !important;
//             }
//     }
//     .form-cadastro-perfil{
//         width: 1000px;
//         @media(max-width:1280px) {
//             width: 100%;
//           }
//     }
//     h1{
//     font-size: 25px;
//     }
   
// }
.alerta-user{
   // width:270%;
    margin:auto !important;
    text-align: center;
    @media(max-width:1280px) {
        text-align: left;
      }
}


// #nomePesquisa{
//     width: 300px !important;
//     @media(max-width:1280px) {
//         width: 100% !important;
//        }
    
// }
// #statusPesquisa{
//     width: 200px !important;
//     @media(max-width:1280px) {
//         width: 100% !important;
//        }
    
// }
.btn-pesquisas{
    font-size: 13px;
}
.div-pesquisa-status{
    width: 70% !important;
    @media(max-width:1280px) {
        margin-top: 20px;
       }
}
.conteudo-botoes{
    display: flex;
    justify-content: space-around;
    @media(max-width:1280px) {
       flex-direction: column;
      }
}
.containPerfil{
	position: relative;
	//align-items: center;
	justify-content: center !important;
	//background-color: aqua;
	height: 880px !important;
	height: auto;
	padding-left: 110px !important;
	padding-right: 50px !important;
	padding-top: 20px !important;
	padding: 40px;
	@media (max-width:1280px){
		padding: 10px !important;
	    margin-bottom: 40px;
        height: 1290px !important;
	  }
  }
  .conteudo-usercad{

    line-height: 10px !important;
    padding-top: 0px;
    margin-top: 20px !important;
   
   justify-content: center !important;
    align-items: center;
    width: 250px;
    img{
        display: block;
       margin:auto !important;
    }
    h2{
        text-align: center;
        max-height: 25px;
    }
    @media(max-width:1280px) {
        width: 100%;
        padding-left: 0px;
        justify-content: center !important;
     
        margin-bottom: 0px !important;
    
        }
}
.bloco-acesso{
    display: flex;
}
.imagem-user-name{
    width: 200px;
    height: 200px;
    overflow: hidden;
    border-radius: 50%;
}
#dropimg{
    display: none;
}

.foto-test{
    width: 200px;
    height:200px;
    margin-right: 20px;
    bottom: 20px !important;
     background-image: url("https://localhost:5001//uploads/Usuarios/admin/image.png");
     background-size: 80%;
     background-repeat: no-repeat, repeat;   
}
.btn-salvarparceiro{
    margin-top: 15px !important;
}
.imagem-user-name2{
    width: 150px;
    height: 150px;
    overflow: hidden;
    border-radius: 50%;
}
.buttonRedefinir{
    margin: auto !important;
    margin-top: 20px !important;
    right: 0;
   // margin-left: 50px !important;
}
.divRecuperar{
    margin: auto;
    margin-top: 20px !important;
    box-shadow: 0 2px 4px 1px #121213 !important;
    width: 90%;
    height: 190px;
    align-items: center;
    text-align: center;
    padding-top: 10px;
    padding-left: 50px;
    padding-right: 50px;
    @media(max-width:1280px) {
        width: 100%;
        height: 205px;
    }
}
.naoRecuperar{
    display: none;
}
.divRede{
    margin-top: 30px;
background-color: #80c0e2;
width: 100%;
justify-content: center;
text-align: center;
padding:5px;
min-height: 45.9px !important;
}
.divRede2{
    margin-top: 49px;
background-color: #80c0e2;
width: 115%;
justify-content: center;
text-align: center;
padding:5px;
min-height: 45.9px !important;
@media(max-width:1280px) {
    display: none;
}
}