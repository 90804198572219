// * NX = not expanded */
@import '../../../styles/colors.scss';
.side-nav-container {
	background-color: var(--dark);
	width: 380px;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
	//position: absolute !important;
    z-index: 1000;
	color: var(--light);
    background-color: rgb(46, 45, 45);
	transition: 0.4s;
    color: #e0dede !important;
	text-decoration: none;
    line-height: 40px;
   
    padding-left: 20px;
    a{
        color: #e0dede !important;
        text-decoration: none !important;
        font-size: 17px;
    }
    a:hover{
        color: #2498d7 !important;
    }
    
    transition: width 500ms !important;
    @media (max-width:1280px){
      min-height:1174px;
      width: 390px !important;
      left: -90px;
      line-height: 70px;
      padding-left: 0px;
      .menu-mobiledmin{
        display: block !important;
       padding-top: 0px !important;
      }
      a{
        text-align: center;
        // font-size: 10px;
        display: block;
      }
      li{
        text-align: center;
        margin-bottom: 50px;
      }
    }
}
.iconMenuAdmin{
  display: none !important;
}
.iconMenuAdmin {
@media(max-width:800px){
  display: block;
 z-index: 4;
 width: fit-content;
 height: 20px;
 cursor: pointer;
 &.iconActive{
  
    .hamburguer{
      background: transparent;
      &::after{
          top:0;
          background: #6d6c6c;
          transform: rotate(225deg);
        }
      &::before{
          top:0;
          background: #6d6c6c;
          transform: rotate(135deg);
        }
    }
  }
 .hamburguer{
  top: 40%;
  //right: 5%;
  width: 25px;
  height: 3px;
  background: #6d6c6c;
  position: absolute;
  transition: 0.5s;
  &::before{
    top: -9px;
    content:'';
    position: absolute;
    width: 25px;
    height: 3px;
    background: #6d6c6c;
    transition: 0.5s;
  }
  &::after{
    top: 9px;
    content:'';
    position: absolute;
    width: 25px;
    height: 3px;
    background: #6d6c6c;
    transition: 0.5s;
  }
}
}
}
.menuNoEspand{
  @media (max-width:1280px){
    .slad-nav-active{
    transition: width 500ms !important;
    left: -120;
    }
    }
}
.menuEspand{
  @media (max-width:1280px){
    .slad-nav-active{
    transition: width 500ms !important;
    left: 0;
    }
    }
}




.side-nav-container-NX {
	width: 87px;
  //width: 110px;
}

.nav-upper,
.nav-heading,
.nav-menu,
.menu-item,
.nav-footer {
	/* border: 2px solid white; */
	display: grid;
}

.nav-heading {
	grid-template-columns: 2fr 1fr;
	grid-template-rows: 1fr;
	height: 75px;
    font-weight: normal !important;
}

.nav-brand {
	display: flex;
	color: var(--light);
}
.nav-brand img {
	width: 40px;
	padding: 0 10px;
}

.hamburger {
	background: none;
	border: none;
	cursor: pointer;
	margin: auto;
    background-color: black;
}

.hamburger span {
	display: block;
	margin-top: 5px;
	background-color: var(--light);
	border-radius: 15px;
	height: 5px;
	width: 35px;

	transition: 0.4s;
}

.hamburger:hover span {
	background-color: var(--primary);
}

.hamburger-in:hover span:nth-child(1) {
	width: 25px;
	transform: translateY(4px) rotate(-25deg);
}
.hamburger-in:hover span:nth-child(2) {
	width: 40px;
}
.hamburger-in:hover span:nth-child(3) {
	width: 25px;
	transform: translateY(-4px) rotate(25deg);
}
/* ///////////////////// */
/* ///////////////////// */
/* ///////////////////// */
/* ///////////////////// */
.hamburger-out {
	margin-left: 24px;
}
.hamburger-out:hover span:nth-child(1) {
	width: 25px;
	transform: translate(14px, 4px) rotate(-155deg);
}
.hamburger-out:hover span:nth-child(2) {
	width: 40px;
}
.hamburger-out:hover span:nth-child(3) {
	width: 25px;
	transform: translate(14px, -4px) rotate(155deg);
}

.nav-menu {
	grid-template-rows: repeat(7, 1fr);
	margin-top: 50px;
    max-height: 100% ;
}

.menu-item {
	height: 57px;
	display: flex;
	color: var(--light);
	text-decoration: none;
	text-transform: uppercase;
	margin: auto 20px;
	border-radius: 10px;
}

.menu-item-NX {
	margin: auto;
}
.menu-item:hover {
	background-color: var(--primary);
}
.menu-item img {
	width: 30px;
	padding: 0 20px;
}

.nav-footer {
	width: 100%;
	height: 87px;
	position: absolute;
	bottom: 0;
	grid-template-rows: 1fr;
	grid-template-columns: 2fr 1fr;
}

.nav-details {
	display: flex;
}
.nav-details img {
	width: 50px;
	padding: 0 20px;
}

.nav-footer-user-name {
	font-size: 18px;
	font-weight: 900;
}

.nav-footer-user-position {
	margin-top: -15px;
	color: var(--gray);
}
.logout-icon {
	width: 30px;
	margin: auto;
	border-radius: 90px;
	padding: 20px;
	margin-left: 5px;
}
.logout-icon:hover {
	background-color: var(--primary);
}


#sidebar{ 
  height: 100% !important;
  width: 272px;
  position: absolute;
  left: 0;
  top: 100;
  z-index: 100;
  background: $black;
  color: #fff;
  transition: width 500ms;
  @media (max-width:1280px){
    width: 96px;
    left: -100%;
  }
  .sidebar-header{
    height: 60px;
    padding: 0rem 1rem;
    position: relative;
    display: flex;
    justify-content: space-between;
    .brand {
      img{
        fill: #fff;
      }
    }
    
  }
  .sidebar-menu{
    position: absolute !important;
    z-index: 2000 !important;
   
    padding: 1rem 0;
    ul{
      text-decoration: none;
      list-style-type: none;
      padding: 0;
      li{
        padding: 1rem;
        &:hover{
          background-color: #4BB7F1;
          a{
            color: $bg-white;
          }
        }
        a{
          text-decoration: none;
          color: $gray-2;
          font-size: .9rem;
          span{
            &:last-child{
              padding-left: .6rem;
            }
          }
        }
      }
    }
    @media(max-width:1280px){
      width: 80px !important;
    }
  }
}
#sidebar-toogle{
  &:checked{
    ~{
      .sidebar{
        .sidebar-header{
          display: flex;
          justify-content: center;
          
        }
      }
      .sidebar{
        li{
          display: flex;
          justify-content: center;
          a{
            @media(max-width:1280px){
              display: flex;
              flex-direction: column;
              align-items: center;
              font-size: 12px;
            }
            span{
              &:last-child{
                display: none;
                @media(max-width:1280px){
                  display: block;
                  text-align: center;
                  padding: 0;
                }
              }
            }
          }
        }
      }

      .main-content{
        margin-left: 96px;
      }
    }
  }
}

.main-content{
  position: relative;
  margin-left: 272px;
  transition: margin-left 500ms;
  @media (max-width:1280px) {
    margin: 0;
    margin-left: 0px;
  }
}
#sidebar-toogle{
  display: none;
}
.arrow-bar{
  display: flex;
  align-items: center;
  justify-content: center;
  background: $black;
  border-radius: 50px;
  position: absolute;
  width: 48px;
  height: 32px;
  right: -22px;
  z-index: 100;
  margin-top: 50px;
  border: 2px solid $white;
  svg{
    fill: $white;
  }
  @media (max-width:1280px) {
    display: none;
  }
}
//margin top===============================
.buttonUpdate{
  margin-top: 20px;
  border: 1px solid #ADADAD;
  background-color: white;
  padding: 8px 16px;
  border-radius: 50px;
  color: #ADADAD;
}
//menu toggle=======================================
.iconMenu{
  display: none;
}
.iconMenu {
@media(max-width:1280px){
  display: block;
 z-index: 4;
 width: fit-content;
 height: 25px;
 cursor: pointer;
 &.iconActive{
  
    .hamburguerD{
      background: transparent;
      &::after{
          top:0;
          background: #6d6c6c;
          transform: rotate(225deg);
        }
      &::before{
          top:0;
          background: #6d6c6c;
          transform: rotate(135deg);
        }
    }
  }
 .hamburguerD{
  top: 50%;
  right: 5%;
  width: 25px;
  height: 3px;
  background: rgb(255, 255, 255);
  position: absolute;
  transition: 0.5s;
  &::before{
    top: -9px;
    content:'';
    position: absolute;
    width: 25px;
    height: 3px;
    background: rgb(255, 255, 255);
    transition: 0.5s;
  }
  &::after{
    top: 9px;
    content:'';
    position: absolute;
    width: 25px;
    height: 3px;
    background: rgb(255, 255, 255);
    transition: 0.5s;
  }
}
}
}
 MUDANÇA DE PADDING E SHADOW ENAVBAR COLLAPSE

 #menuDark {
   @media(max-width:1280px){
   padding: 20px;
   flex-basis: 100%;
   background-color: #000;
   flex-grow: 1;
   align-items: center;
   box-shadow: 6px 15px 30px -20px;
   width: 94%;
   position: absolute;
   top: 96px;
   border-top: solid 2PX #e0dede; 
   z-index: 1000;
 }
 }
 #navbarMenuD{
   background-color: rgb(46, 45, 45);
   z-index: 1000;
   align-items: center;
 }

 .login{
  @media(max-width:820px){

   padding-left: 0 !important;
  }
 }

.menuSumir{
  display:none;
  transition: 1s;
}
.menu-mobiledmin{
  display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50px;
    position: absolute;
    width: 48px;
    height: 32px;
    right: -45px;
    z-index: 100;
    top:15px;
   display: none !important;
}
.menuNoEspand{
  @media (max-width:1280px){
    .slad-nav-active{
    transition: width 500ms !important;
    left: -390px;
    }
    }
}
.menuEspand{
  @media (max-width:1280px){
    .slad-nav-active{
    transition: width 500ms !important;
    left: 0;
    }
    .menu-mobiledmin{
      right: 50px;
      top: 5px;
    }
  }
    
    
}
@media (min-width:1280px){
  .textmobile{
    display: none;
  }
 
  }
  #logo1{
    margin-right: 14px;
    @media (max-width:1280px){
      margin-left: 24px !important;
  
    }
  }
  #logo2{
    margin-right: 20px;
    @media (max-width:1280px){
      margin-left: 36px !important;
      margin-right: 30px;
  
    }
  }
  #logo3{
    margin-right: 20px;
    @media (max-width:1280px){
      margin-left: 36px !important;
      margin-right: 30px;
  
    }
  }
  #logo4{
    margin-right: 13px;
    @media (max-width:1280px){
      margin-left: 36px !important;
      margin-right: 30px;
  
    }
  }
   .submenu-cad{
    
     width: 330px;
     margin-top: 0px!important;
     background-color: #3c3c3c;
     padding-left: 30px;
     span{
       letter-spacing: 1px;
       font-size: 14px!important;
       display: block !important;
     }
     li{
       display: flex !important;
       cursor:pointer;
     }
     @media (max-width:1280px){
     position: absolute;
     padding-left: 20px;
     margin-left: 120px;
     top: 0;
     z-index: 1000 !important;
     ul{
      padding-top: 15px;
      width: 280px;
     }
     }
   }
   .menu-no-cadastros{
     ul{
       display: none;
     }
     transition: 0.5s;
     height: 0px;
   }
   .accordion-button{
    background-color: #2e2e2e!important;
    color: #e0dede;
   }
   .accordion-button:hover{
   
    color: #4BB7F1;
   }
   .accordion-body{
    padding: 1px !important;
   // padding-left: 10px !important;
    color: #e0dede;
    background-color: #555454!important;
   }
   .accordion-body:hover{
    color: #4BB7F1;
  
   }
   .menuFechado{
    margin-top: 5px !important;
    width: 65px !important;
    .accordion-button{
      padding: 1px !important;
    }
    #logo-admin{
      font-size: 30px !important;
    }
    #logo-admin2{
      font-size: 24px !important;
      margin-left: 4px !important;
      margin-right: 15px !important;
    }
    @media (max-width:1280px){
      width: 340px !important;
    }
   }
   .menuAberto{
    transition: 0.7s;
    width: 350px !important;
    @media (max-width:1280px){
      width: 340px !important;
    }
    .accordion-button{
      padding: 5px !important;
    }
    #logo-admin{
      font-size: 27px !important;
      margin-right: 15px !important;
    }
    #logo-admin2{
      font-size: 24px !important;
      margin-left: 4px !important;
      margin-right: 15px !important;
    }
   }
   .menuInterativo{
    margin-top: 30px !important;
    @media (max-width:1280px){
      margin-left: 30px;
    }
   }
   .invisivel{
    transition: 0.7s;
    display: none;
   }
   .bloco-botoes-finalizar{
    text-align: center !important;
    align-items: center;
   }
 